import * as React from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  size,
  shift,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import { DropdownBlock, type CommonBlockProps } from '../../../types'
import { ComponentProps } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useWebchatStore } from '../../../hooks'
import { webchatClasses } from '../../../styles/classes'

type Props = DropdownBlock & ComponentProps<'div'> & CommonBlockProps

export const Dropdown = ({ options, label, reusable }: Props) => {
  const sendTextMessage = useWebchatStore((s) => s.sendTextMessage)

  const messageContainerRef = useWebchatStore((s) => s.messageContainerRef)
  const isReadOnly = useWebchatStore((s) => s.isReadOnly)

  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null)

  const {
    message: {
      blocks: { dropdown },
    },
  } = webchatClasses

  const optionsDict = options.reduce(
    (acc, option) => {
      acc[option.label] = option.value
      return acc
    },
    {} as { [key: string]: string }
  )

  const labels = optionsDict ? Object.keys(optionsDict) : []

  const onOpenChange = (open: boolean) => {
    if (selectedIndex !== null && !reusable) {
      setIsOpen(false)
    } else {
      setIsOpen(open)
    }
  }

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      shift({
        padding: 10,
        boundary: messageContainerRef?.current ?? undefined,
        crossAxis: true,
      }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })

  const listRef = React.useRef<Array<HTMLElement | null>>([])
  const listContentRef = React.useRef(labels)
  const isTypingRef = React.useRef(false)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  })
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping
    },
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    typeahead,
    click,
  ])

  const handleSelect = (index: number) => {
    setSelectedIndex(index)
    if (labels[index] !== undefined) {
      void sendTextMessage(optionsDict[labels[index]] ?? '')
    }
    setIsOpen(false)
  }

  const selectedItemLabel = selectedIndex !== null ? labels[selectedIndex] : undefined

  return (
    <>
      <div
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        data-disabled={selectedIndex !== null && !reusable ? '' : undefined}
        {...dropdown.button?.container}
        {...getReferenceProps()}
      >
        <span {...dropdown.button?.text}>{selectedItemLabel || label || 'Select...'}</span>
        <ChevronDownIcon {...dropdown.button?.icon} />
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className={dropdown.content?.container?.className}
              // className="min-w-[100px] overflow-y-auto rounded-md border border-gray-200 bg-white text-sm text-gray-700 outline-none"
              style={{ ...floatingStyles }}
              {...getFloatingProps()}
            >
              {labels.map((value, i) => (
                <div
                  key={value}
                  ref={(node) => {
                    listRef.current[i] = node
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  data-active={i === activeIndex ? '' : undefined}
                  {...dropdown.content?.item}
                  {...getItemProps({
                    onClick() {
                      if (isReadOnly) {
                        return
                      }
                      handleSelect(i)
                    },
                    onKeyDown(event) {
                      if (isReadOnly) {
                        return
                      }
                      if (event.key === 'Enter') {
                        event.preventDefault()
                        handleSelect(i)
                      }

                      if (event.key === ' ' && !isTypingRef.current) {
                        event.preventDefault()
                        handleSelect(i)
                      }
                    },
                  })}
                >
                  {value}
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
